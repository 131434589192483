<template>
  <div class="wrapper">
    <div class="row mb-1">
      <button
        class="btn btn-sm btn-outline-primary mr-2 shadow-none"
        @click="filtered"
      >
        применить фильтр
      </button>
      <select
        class="form-control form-control-sm shadow-none col-md-1"
        @change="filtered"
        v-model="limit"
        size="1"
      >
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
        <option value="all">все</option>
      </select>
      <span v-if="limit !== 'all'">последних</span>
    </div>
    <div class="overflow-auto">
      <table class="table table-sm">
        <thead>
          <tr>
            <th colspan="2">№</th>
            <th>№ СЦ</th>
            <th v-if="USER_INFO.status === 'admin'">СЦ</th>
            <th>создан</th>
            <th>инструмент</th>
            <th>группа</th>
            <th>S№</th>
            <th>дата продажи</th>
            <th>дата принятия в ремонт</th>
            <th>дефект</th>
            <th>продавец</th>
            <th>статус</th>
          </tr>
        </thead>
        <tr>
          <td
            v-for="(item, idx) in REPAIRSLISTALLFILTER"
            :key="idx + 'f'"
            :colspan="idx === 0 ? 2 : 1"
          >
            <input
              type="text"
              size="1"
              class="filter form-control shadow-none"
              v-model="item.value"
              v-if="!item.select"
              :placeholder="item.placeholder"
            />
            <select
              size="1"
              v-else
              class="form-control form-control-sm shadow-none"
              @change="filtered"
              v-model="item.value"
            >
              <option
                v-for="(item2, idx2) in item.options"
                :key="idx2 + 'o'"
                :value="item2.field"
                >{{ item2.value }}</option
              >
            </select>
          </td>
        </tr>
        <tbody>
          <tr class="cursor" v-for="(item, idx) in repairs_list" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td class="nowrap">
              <div
                :class="
                  item.who_saw && USER_INFO.status === 'admin' ? 'who-saw' : ''
                "
              >
                {{ item.repair_number }}
              </div>
              <div v-if="item.who_saw_all && USER_INFO.status === 'admin'">
                <div
                  v-for="(item_who, idx) in item.who_saw_all"
                  :key="idx + 'who_saw'"
                >
                  {{ item_who.user }}
                </div>
              </div>
              <div
                v-if="
                  item.duplicate_serial_number && USER_INFO.status === 'admin'
                "
              >
                <a
                  :href="
                    `https://sc-kalibr.ru/repairs/repair/${item.duplicate_serial_number}`
                  "
                  target="_blank"
                  @click.prevent="view_duplicate(item)"
                  >дубликат {{ item.duplicate_serial_number_count }}шт.</a
                >
              </div>
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.receiptNumber }}</td>
            <td
              @click="goRepair(item.remont_id)"
              v-if="USER_INFO.status === 'admin'"
            >
              {{ item.user }}
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.date_add }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.name_instr }}</td>
            <td @click="goRepair(item.remont_id)">
              {{ item.serialNumberGroup }}
            </td>
            <td @click="goRepair(item.remont_id)">{{ item.serialNumber }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.dateSale }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.dateRepair }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.defect }}</td>
            <td @click="goRepair(item.remont_id)">{{ item.seller }}</td>
            <td v-html="status(item)"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="loading" />
    <DuplicateViews
      v-if="USER_INFO.status === 'admin'"
      :open="openDialog"
      :repairsId="duplicateId"
    />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loader from "@/components/Loader";
import DuplicateViews from "@/components/DuplicateViews.vue";
export default {
  name: "RepairsListAll",
  components: {
    Loader,
    DuplicateViews
  },
  computed: {
    ...mapGetters([
      "USER_INFO",
      "PATH",
      "REPAIRSLISTALLFILTER",
      "REPAIRSLISTALLLIMIT",
      "REPAIRS_LIST_SCROLL"
    ]),
    limit: {
      get() {
        return this.REPAIRSLISTALLLIMIT;
      },
      set(value) {
        this.set_limit(value);
      }
    }
  },
  data: () => ({
    repairs_list: null,
    loading: false,
    openDialog: false,
    duplicateId: null
  }),
  mounted() {
    this.ADDPATH("все ремонты"), this.get_data(), this.f();
    this.update_item_filter_inputs();
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations([
      "set_limit",
      "set_item_filter",
      "set_item_filter_admin",
      "set_repairs_list_scroll"
    ]),
    get_data() {
      this.loading = true;
      const filter = this.REPAIRSLISTALLFILTER.filter(
        item => item.value.length > 0
      );
      const data = JSON.stringify(filter);
      HTTPGET({
        url: "repairs/repairs_list_all.php",
        params: {
          filter: data,
          limit: this.REPAIRSLISTALLLIMIT
        }
      })
        .then(response => (this.repairs_list = response.data.repairs))
        .then(() => window.scrollBy(0, this.REPAIRS_LIST_SCROLL))
        .then(() => this.set_repairs_list_scroll(0))
        .then(() => (this.loading = false));
    },
    goRepair(id) {
      this.$router.push(
        `/repairs/repair/${id}?path=${this.PATH}&from=all_repairs`
      );
      this.set_repairs_list_scroll(window.pageYOffset);
    },
    status(item) {
      if (item.typeOfRepair === "noRepair") {
        if (item.repair_confirm_data) {
          return `акт - подтвержден ${item.repair_confirm_data}`;
        } else {
          return "акт - не подтвержден";
        }
      }
      if (item.typeOfRepair === "diagnostiks") {
        if (item.repair_confirm_data) {
          return `диагностика или рбз - подтвержден ${item.repair_confirm_data}`;
        } else {
          return "диагностика или рбз - не подтвержден";
        }
      }
      if (item.typeOfRepair === "repair") {
        if (item.order_id) {
          return `ремонт - <a href="/repairs/order/${item.order_id}?path=">в заявке №${item.order_number} от ${item.order_data}</a>`;
        } else {
          return "ремонт - создан";
        }
      }
    },
    filtered() {
      const filter = this.REPAIRSLISTALLFILTER.filter(
        item => item.value.length > 0
      );
      const data = JSON.stringify(filter);
      this.get_data(data);
    },
    f() {
      if (this.USER_INFO.status !== "admin") {
        this.filter = this.REPAIRSLISTALLFILTER.filter(item => !item.admin);
      }
    },
    update_item_filter_inputs() {
      if (this.USER_INFO.status !== "admin") {
        this.set_item_filter();
      } else {
        this.set_item_filter_admin();
      }
    },
    view_duplicate(id) {
      this.duplicateId = id;
      this.openDialog = !this.openDialog;
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 10px;
}
.table {
  font-size: 0.7em;
}
.nowrap {
  white-space: nowrap;
}
.cursor {
  cursor: pointer;
}
.filter {
  width: 90%;
  height: 2em;
  margin: 0 auto;
  font-size: 1em;
}
select {
  font-size: 1em;
}
th {
  text-align: center;
}
.row {
  justify-content: center;
  align-items: center;
}
span {
  margin: 0 5px;
}
.who-saw {
  background: green;
  color: white;
}
input {
  padding: 1px;
}
input::-webkit-input-placeholder {
  font-size: 0.7em;
}
</style>
